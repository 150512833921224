import React, {Component} from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import AvailableCitiesModal from '../elements/available-cities-modal';

// Images
import bnrImg from "../../images/banner/bnr4.jpg";
import { Helmet } from 'react-helmet';
import { blogsContent } from '../elements/blogs-data'; 

class BlogDetails extends Component{
	createSlug = (title) => title.toLowerCase().replace(/ /g, '-');
	
	render(){
    // Get the slug from the URL
    const { slug } = this.props.match.params;

    // Find the blog item that matches the slug
    const blogItem = blogsContent.find(item => this.createSlug(item.title) === slug);

    // If no blog is found, redirect to a 404 page
    if (!blogItem) {
      return <Link to="/error-404" />;
		}

		return (
			<>
				<Helmet>
					<title>{blogItem.title} - Ride N Repair</title>
					<meta
						name="description"
						content={blogItem.summary}
					/>
					<meta
						name="keywords"
						content="Ride N Repair, bike purchase, buying tips, bike details, motorcycle, two-wheeler, bike myths, bike essentials, bike service"
					/>
				</Helmet>

				{this.props.citiesModal && <AvailableCitiesModal closeModal={() => this.props.setCitiesModal(false)} />}
				
				<Header user={this.props.user} setUser={this.props.setUser} />
				
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bnrImg+")"}}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>Blog Details</span></h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">
									<ul className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>Home</Link></li>
										<li className="breadcrumb-item active" aria-current="page">Blog Details</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
					
					<section className="section-area section-sp1 bg-white">
						<div className="container">
							<div className="row">
								<div className="col-md-12 col-lg-12 col-xl-12 mb-30 mb-md-60">
									<div className="blog-lg blog-single">
										<div className="action-box blog-lg">
											<img src={blogItem.thumb} alt={blogItem.title} />
										</div>
										<div className="info-bx">
											<div className="ttr-post-title">
												<h3 className="post-title">{blogItem.title}</h3>
											</div>
											<div className="post-content" dangerouslySetInnerHTML={{ __html: blogItem.blogText }} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				
				</div>
				
				<Footer setCitiesModal={this.props.setCitiesModal} />
				
			</>
		);
	}
}

export default BlogDetails;