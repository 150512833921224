import React, { Component } from 'react';

import { Route, Switch } from 'react-router-dom';

// Elements
import BackToTop from './elements/back-top';
import PageScrollTop from './elements/page-scroll-top';

// All Pages Router
import Index from './pages/index';
import AboutUs from './pages/about';
import BlogDetails from './pages/blog-details';
import BlogListSidebar from './pages/blog-list-sidebar';
import Booking from './pages/booking';
import ContactUs from './pages/contact-1';
import Faq1 from './pages/faq-1';
import Services from './pages/service-1';
import ThankYou from './pages/thank-you';
import ThankYouEmail from './pages/thank-you-email';
import Error from './pages/error-404';
import TermsAndConditions from './pages/terms-and-conditions';
import PrivacyPolicy from './pages/privacy-policy';
import CancellationPolicy from './pages/cancellation-policy';
import RefundPolicy from './pages/refund-policy';
import MechanicLogin from './pages/mechanic-login';
import MechanicDashboard from './pages/mechanic-dashboard';
import PartnerLogin from './pages/partner-login';
import PartnerDashboard from './pages/partner-dashboard';
import BookingDetails from './pages/booking-details';
import Login from './pages/login';
import Register from './pages/register';
import BookingHistory from './pages/booking-history';


class Markup extends Component{
	render(){
		return(
			<>

				<Switch>
				
					<Route path='/' exact component={(props) => (<Index {...props} citiesModal={this.props.citiesModal} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
					<Route 
            path='/city/:cityName' 
            exact 
            render={(routeProps) => (
              <Index 
                {...routeProps}
                cityKey={routeProps.match.params.cityName}
                citiesModal={this.props.citiesModal} 
                setCitiesModal={this.props.setCitiesModal} 
                openModal={this.props.openModal} 
                setOpenModal={this.props.setOpenModal} 
                user={this.props.user} 
                setUser={this.props.setUser} 
              />
            )} 
          />
					<Route path='/about' exact component={(props) => (<AboutUs {...props} citiesModal={this.props.citiesModal} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
					<Route path='/blog-details/:slug' exact component={(props) => (<BlogDetails {...props} citiesModal={this.props.citiesModal} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
					<Route path='/blogs' exact component={(props) => (<BlogListSidebar {...props} citiesModal={this.props.citiesModal} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
					<Route path='/booking' exact component={(props) => (<Services {...props} citiesModal={this.props.citiesModal} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
					<Route path='/checkout' exact component={(props) => (<Booking {...props} citiesModal={this.props.citiesModal} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
					<Route path='/contact' exact component={(props) => (<ContactUs {...props} citiesModal={this.props.citiesModal} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
					<Route path='/faq' exact component={(props) => (<Faq1 {...props} citiesModal={this.props.citiesModal} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
					<Route path='/service' exact component={(props) => (<Services {...props} citiesModal={this.props.citiesModal} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
					<Route path='/thank-you' exact component={(props) => (<ThankYou {...props} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
					<Route path='/thank-you-email' exact component={(props) => (<ThankYouEmail {...props} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
					<Route path='/terms-and-conditions' exact component={(props) => (<TermsAndConditions {...props} citiesModal={this.props.citiesModal} setCitiesModal={this.props.setCitiesModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
					<Route path='/privacy-policy' exact component={(props) => (<PrivacyPolicy {...props} citiesModal={this.props.citiesModal} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
					<Route path='/cancellation-policy' exact component={(props) => (<CancellationPolicy {...props} citiesModal={this.props.citiesModal} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
					<Route path='/refund-policy' exact component={(props) => (<RefundPolicy {...props} citiesModal={this.props.citiesModal} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
					<Route path='/mechanic-login' exact component={(props) => (<MechanicLogin {...props} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
					<Route path='/mechanic-dashboard' exact component={(props) => (<MechanicDashboard {...props} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
					<Route path='/partner-login' exact component={(props) => (<PartnerLogin {...props} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
					<Route path='/login' exact component={(props) => (<Login {...props} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
					<Route path='/register' exact component={(props) => (<Register {...props} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
					<Route path='/partner-dashboard' exact component={(props) => (<PartnerDashboard {...props} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
					<Route path='/booking-history' exact component={(props) => (<BookingHistory {...props} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
					<Route path='/booking-details' exact component={(props) => (<BookingDetails {...props} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
					<Route path='/error-404' exact component={(props) => (<Error {...props} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
					<Route component={Error} />
					
				</Switch>
				
				<PageScrollTop />
						
				<BackToTop />
				
			</>
		);
	}
}

export default Markup;