import blogThumb1 from '../../images/blog/grid/pic1.webp';
import blogThumb2 from '../../images/blog/grid/pic2.webp';
import blogThumb3 from '../../images/blog/grid/pic3.webp';

export const blogsContent = [
	{ 
		thumb: blogThumb1,
		title: "Essential Details You Need To Know Before Purchasing A New Bike",
    slug: "essential-details-you-need-to-know-before-purchasing-a-new-bike",
		summary: "Purchasing your first bike is thrilling, but it's crucial to consider factors like budget, engine size, and fuel efficiency. Dive into our comprehensive guide to ensure you make an informed decision, covering everything from bike types to customer reviews.",
		blogText: `
      <p>Purchasing your first bike is an exhilarating experience and gives you an adrenaline rush. To make sure you get the most out of your dream bike, it is important to know what to consider. That is where <strong>Ride N Repair</strong> comes in - we will make sure you are aware of all the essential details you need to know before purchasing a bike. Find out everything you need to know by reading further!</p>

      <h4>Types of Bikes You Can Buy</h4>
      
      <p>You should carefully consider how you will use your bike before making a purchase. There are different types of bikes designed for different purposes - from sports bikes that prioritize speed and agility, to street bikes that are perfect for commuting in the city, to cruiser bikes that offer a more comfortable ride. Therefore, choose the bike that is more suitable for you.</p>
      
      <h4>Check the Price Range</h4>
      
      <p>The most important thing to think about when buying a bike is your budget. You need to figure out how much you are willing to spend on a new bike, so you can narrow your choices down to the models that fit your price range. Do not forget to factor in the costs of things like GST, bike insurance, RTO taxes, fuel, registration fees, etc. Also, keep in mind that you will need to budget for regular maintenance and repairs.</p>
      
      <h4>Consider the Engine Size</h4>
      
      <p>When choosing a motorcycle, engine capacity is a key factor to keep in mind. The engine size is measured in cubic centimeters (cc) and indicates the motorcycle's power. If you plan to commute regularly or ride long distances, you can choose any bike from 125cc to 350cc. Buying a superbike is not really well-suited for crowded city roads; however, you can purchase a cruiser-style bike with a high-torque engine that has a comfortable low seat, and contains other powerful features, making every ride an enjoyable experience.</p>
      
      <h4>Check the Weight and Height</h4>
      
      <p>It is essential to invest in a heavy-duty motorcycle since it can provide better safety and control. This is especially important at low speeds, as even a slight imbalance can lead to loss of control. Keep in mind that while you are mounted on the vehicle, your foot is firmly landing on the ground. In order to have a smooth ride, get a motorcycle that matches your muscular strength and height. In order to ensure that you are buying a vehicle that suits your needs, always ask for a test drive.</p>
      
      <h4>Consider Fuel Efficiency</h4>
      
      <p>It is crucial to check the fuel efficiency of your bike to make the most of it. Choosing a moderately powered bike will help you get great fuel efficiency and stop avoidable costs. Also, keep in mind that fuel usage depends on riding style and maintenance.</p>
      
      <h4>Check Customer Reviews</h4>
      
      <p>Consider checking out customer and expert reviews of your chosen two-wheeler models as one of the most important tips. By reading authentic testimonials about the pros and cons of two-wheelers, you will gain a better understanding of their overall performance and the problems they face.</p>
      
      <p>Research is key when buying a bike and these are the essential details you need to know before making a decision. You must contemplate what is important to you as you search for your new set of wheels. Now, <strong>Ride N Repair</strong> is with you every step of the way, providing doorstep bike service at your convenience.</p>
    `,
	},
  {
		thumb: blogThumb2,
    title: "Good-to-have Motorcycle Accessories For Your Rides",
    slug: "good-to-have-motorcycle-accessories-for-your-rides",
    summary: "Discover essential motorcycle accessories that not only enhance your riding experience but also prioritize safety. From riding gloves to toolkit essentials, Ride N Repair offers insights for every passionate rider.",
    blogText: `
      <p>Motorcyclists love to explore and find new and exciting places to ride. The open road can be incredibly beckoning, and they do not think about the long miles ahead. With the proper precautions in place, you can enjoy the ride without worrying.</p>
      
      <h4>A helmet is an essential piece of gear for all bike riders. It not only provides more protection and coverage in the event of an accident, but it is also important for the safety of your pillion passenger. Make sure both you and your passenger are wearing helmets every time you ride.</h4>
      
      <p>Here’s Ride N Repair providing a list of good-to-have motorcycle accessories for your rides.</p>
      
      <h4>Riding Gloves</h4>
      
      <p>The gloves maintain the grip you need on long rides and keep your hands safe from injury. It can help you to prevent numbness in your hands. It acts like a second skin and avoids sunburn. Look for gloves that are warm, convenient, water resistant, and provide better protection and a full scope of movement. Additionally, leather riding gloves can also add a touch of style to your look while you are out on the open road. Good biking gloves have increased airflow intake. It also provides vital protection in vulnerable places. Most decent motorcycle gloves come with knuckle and finger protection, as well as plastic, carbon fibre, or resin sliders situated on the palm area. </p>
      
      <h4>Riding Jackets</h4>
      
      <p>Motorcycle jackets are expensive, but can provide protection in the event of a crash. It helps to elevate your style quotient, especially in leather jackets. You can also use it as a shield when there is bad weather. The pockets of the jacket can help you carry some important items with you as they have pockets, zippers, and buttons. You can buy jackets with excellent components, well-ventilated, and high-quality features such as jackets equipped with a spine protector, shoulder and elbow protectors, and chest protection.</p>
      
      <h4>Motorcycle Toolkit</h4>
      
      <p>A motorcycle toolkit is an essential accessory for any motorcycle rider. It includes everything you need to make basic repairs and adjustments, such as flashlights, screwdrivers, wrenches, pliers, electrical tape, and more. You can customize your kit based on the specific needs of your bike. There will be no extra hassle and expenses. It is easy to carry and lightweight, so you can put it in your luggage or in an under-seat locker. In case of an emergency, it will help you in the middle of a no-garage area.</p>
      
      <h4>Seat Cover</h4>
      
      <p>If you are looking for a way to add some personality to your motorcycle and keep it protected from the elements, consider a motorcycle seat cover.  Available in various colors and designs, these are made from high-quality materials like fabric or polyester, making them easy to fit. It can also help protect your bike from water, dust, and heat. The seat covers over the stock seat will provide some extra cushioning that will make your daily commute more comfortable.</p>
      
      <h4>Handle Grips</h4>
      
      <p>The handle grips play an important role in enhancing your riding comfort. If you are feeling numbness or pain in your hands after hours of riding, it might be due to the continuous use of the clutch and accelerator. The hand grips on your bike should be made of high-quality rubber and aluminium to help alleviate any pain you might feel. During your rides, they offer better control and protection to your hands and keep you comfortable throughout your bike journey. When steering and balancing on the bike, it provide the riders with better stability and control.
      </p>
      
      <p>These are some good-to-have motorcycle accessories for your rides that every rider should have. Make sure your motorcycle is serviced by <strong>Ride N Repair</strong> Doorstep Bike Service before you set out on your ride to avoid unanticipated accidents. </p>
    `
  },
  {
		thumb: blogThumb3,
    title: "Unbelievable Myths about Bikes",
    slug: "unbelievable-myths-about-bikes",
    summary: "Ride N Repair debunks widespread myths about motorcycles, from the dangers of loud pipes to misconceptions about braking techniques. Get clarity on these myths to ensure safer and informed riding experiences.",
    blogText: `
      <p>In today’s world, it is easier than ever for rumors to spread quickly, and myths are still widely believed by people. There are many misconceptions out there that can confuse you about two-wheelers. Ride N Repair Doorstep Bike Service dispels the unbelievable myths about bikes that you often hear.</p>
      
      <h4>Racing expeditiously fast</h4>
      
      <p>Racing tires are specifically designed for racing conditions and should not be used on public roads. High speeds on a race track require a firm clutch, which can be dangerous on city roads. In addition, racing tires can get very hot, making them less safe in wet weather. Hence, it is important to use standard tires when riding in town. They offer better grip, safety, and stability in all weather conditions.</p>
      
      <h4>Loud pipes make riding safer</h4>
      
      <p>“Loud pipes save lives” is a poorly backed notion. The exhaust pipe on the motorcycle makes an increased noise that is meant to attract or alert others who are nearby, but this noise is actually directed towards the rear of the bike. This can make it harder for the vehicle in front of you to hear, especially if that car has its windows rolled up, or the radio is on. Also, it is important to note that riding a motorcycle with a high exhaust note can be dangerous, as it can cause a heart attack for people nearby and often startles people on the roads.</p>
      
      <h4>Helmets provide more auditory perception and less visibility</h4>
      
      <p>Some riders think that helmets are annoying to wear because they block noise and do not provide full visibility. Typically, helmet standards provide 210 degrees of peripheral vision, but it does not reduce your vision. The helmet visor helps to keep out dust and debris. They are designed to cancel out noise for better hearing, although the rider can hear traffic noise clearly due to the vents built into the motorcycle helmet.</p>
      
      <h4>Recline your bike if you intend to crash</h4>
      
      <p>It is a ridiculous idea to lay your bike down when you might crash. It will not help you prevent yourself from getting hurt. Sliding down a bike and hitting another vehicle can cause serious injuries. The best option is to ride slowly and sensibly so you can swerve to the best of your abilities.</p>
      
      <h4>Small objects obstruct your way</h4>
      
      <p>People have this perception that if a small object gets in your way, you could lose control and crash your bike. However, if you know how to ride a bike properly, you should be fine. In fact, encountering loose gravel or a potential pothole on the way are relatively common occurrences that can sometimes lead to accidents.</p>
      
      <h4>Flying off the bike while clutching the front brakes</h4>
      
      <p>This myth is completely counterintuitive. Contrary to popular belief, the front brake is the most powerful brake on a bike. This depends on your momentum, and how quickly you hit the brake. So, if you want to slow down quickly, always use the front brake.</p>
      
      <p>These unbelievable myths about bikes must be avoided because they might often lead to unforeseeable accidents. If you need a bike service, <strong>Ride N Repair</strong> Doorstep Bike Service can offer the best fix-up for your bike, so what are you waiting for? Get in touch with <strong>Ride N Repair</strong> today!</p>"
    `
  }
]